/******
    Activity Card Variables
******/
$__activity-card-checker-size: 2rem;
$__activity-card-checker-spacer: 1rem;


/******
    Activity Card Element
******/
.activity-card {

  // ----
  //  Set PanelBody Shadow
  // ----
  .body {
    @include smoothShadow(2);
    transition: box-shadow $transition-ease $transition-duration;
    cursor: pointer;

    /// Change the Shadow on Hovering
    /// all card to display interaction to open modal window
    @include hover {
      &:hover {
        @include smoothShadow(4);

        /// Show the trash action
        .activity-card-header .activity-card-action {
          display: block;
        }
      }
    }
  }


  // ----
  //  Style the ActivityCard Body Element
  // ----
  .activity-card-body {
    padding-left: $__activity-card-checker-size + $__activity-card-checker-spacer;
    margin-top: 1em;
  }


  // ----
  //  Adjust Margin
  // ----
  margin: 0 !important;

  /// While displayed plain, could use base selector
  + .activity-card {
    margin-top: 1rem !important;
  }

  /// While displayed into must act on root element
  /// look down


  // ----
  //  Set the Compact Variation
  // ----
  &.compact {
    /// Adjust PanelBody FontSize
    .body {
      font-size: 12px;
    }
  }

}

.sortable-container > div:not(:first-child) > .activity-card {
  margin-top: 1rem !important;
}


/******
    Activity Card Header Style
******/
.activity-card-header {
  display: flex;

  /// Shared Style between checker and action
  .activity-card-checker {
    width: $__activity-card-checker-size;
    height: $__activity-card-checker-size;
    background-color: $white;
    border: solid 3px $cloud;
    border-radius: $border-radius-rounded;
    position: relative;
    margin-right: $__activity-card-checker-spacer;
    transition: background-color $transition-ease $transition-duration,
    border-color $transition-ease $transition-duration;

    > .icon {
      color: transparent;
      transition: color $transition-ease $transition-duration;
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  /// Specific Action Style
  .activity-card-action {
    display: none;
    width: $__activity-card-checker-size;
    height: $__activity-card-checker-size;
    font-size: 1em;
  }

  /// Style the main Header
  .activity-card-title-wrapper {
    flex-grow: 1;

    .activity-card-title {
      font-weight: $semi-bold;
      font-size: 1.25em;
      line-height: $__activity-card-checker-size;
    }

    .activity-card-meta {
      color: $text-secondary;
      font-size: .75em;
    }
  }


  // ----
  //  Set the Activity Done Style
  // ----
  &.done {
    /// Style the Checkmark
    .activity-card-checker {
      border-color: $success;
      background-color: transparentize($success, .25);

      > .icon {
        color: $white;
      }
    }
  }


  // ----
  //  Set Hover
  // ----
  &:not(.done):not(.readonly) .activity-card-checker {
    cursor: pointer;

    @include hover {
      &:hover {
        border-color: $success;

        > .icon {
          color: $success;
        }
      }
    }
  }
}
