.form.submitted > .form-content > .message.is-danger {
  animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  color: $text;
  box-shadow: none !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .header-content {
    font-size: 1em;
    font-weight: $regular;

    > .content {
      font-weight: $bold;
      color: $danger;
      font-size: 1.1em;

      + .subheader {
        margin-top: 0.5em;
      }
    }

    > .subheader {
      font-size: 1em;
      font-weight: $regular;
    }
  }
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(5px, 0, 0);
  }
}
