*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.modal.image-fullscreen-preview {
  padding: 0;
  width: auto;
  display: inline-block;
  max-width: 95% !important;
}


.panel > .body.clickable {
  cursor: pointer;
  @include smoothShadow(0);
  transition: box-shadow $transition-duration $transition-ease;

  @include hover {
    &:hover {
      @include smoothShadow(5);
    }
  }
}


.labels.selectable-labels > .label {
  display: block;
  margin: $labels-spacer * 2 $labels-spacer;
}

.popup .labels.selectable-labels > .label {
  font-size: 1rem;
}


/******
    Fix the Item Content size when small
******/
.item.is-small .content {
  font-size: 1.25em;
}


/******
    Icons Fix for DuoTone single Icon
******/
.icon.fa-plus,
.icon.fa-times,
.icon.fa-xmark {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}


/******
    Add Margin to Modal Content inside form
******/
.modal-header + .form > .modal-content {
  margin-top: $modal-vertical-padding;
}
