.markdown-coder textarea,
.markdown-example-code {
  background-color: $transparent;
  font-family: $font-family-monospace;
  font-weight: $light;
}

.markdown-example-code {
  color: $text-secondary;
  white-space: pre-line;
}

.markdown-preview {
  // ----
  //  Set the Header element Margin increased
  // ----
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5em;
  }

  // ----
  //  Set a Margin for Paragraph
  // ----
  p {
    margin-bottom: .75em;
  }

  // ----
  //  Set Padding for OL and UL
  // ----
  ol, ul {
    padding-left: 2.5rem;
  }

  // ----
  //  Restore Checkbox
  // ----
  ul > li {
    input[type='checkbox'] {
      border: unset;
      clip: unset;
      height: unset;
      overflow: unset;
      padding: unset;
      position: static;
      width: unset;
    }
  }

  // ----
  //  Restore Ordered List Number
  // ----
  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }
}
