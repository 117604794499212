.activities-board {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  .activities-list {
    width: 520px;
    min-width: 520px;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
