/******
    Main Navbar Element
******/
#navbar {
  position: fixed;
  top: 0;
  left: $sidebar-closed-width;
  right: 0;
  height: $navbar-height;
  background-color: $navbar-background;
  padding-left: $view-content-horizontal-padding;
  padding-right: $view-content-horizontal-padding;
  z-index: 9;
}


/******
    Navbar flex content
******/
#navbar .navbar-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: $navbar-padding;
  padding-bottom: $navbar-padding;
  align-items: center;
  border-bottom: solid 1px $navbar-bottom-border-color;

  .navbar-section {
    padding: 0 $navbar-element-spacing * 2;
    display: flex;
    align-items: center;

    &.main {
      flex-grow: 1;
    }

    &.pull-right {
      margin-left: auto;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    > * {
      margin: 0 $navbar-element-spacing;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
