@use 'sass:math';

/******
    Define two different zone of the Home Page
    one with all projects table and another
    with the complete team members
    The Active Projects Table is visible only on tablet-up device
******/
.active-projects-wrapper {
  display: none;
  width: 100%;

  // ----
  //  The Project Details part will grow
  //  and keep all the space left empty by members part
  // ----
  .active-projects-details {
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  // ----
  //  The Members Column will scroll horizontally
  //  to let view all teams
  // ----
  .active-projects-members {
    overflow-x: scroll;
  }

  @include min-screen('tablet') {
    display: flex;
  }
}


.active-projects-board {
  display: block;

  @include min-screen('tablet') {
    display: none;
  }
}


/******
    Define the Table
******/
.active-projects-table {
  table-layout: fixed;

  // ----
  //  Define the Header and Body Row Height
  // ----
  thead > tr > th {
    height: 72px;
  }

  tbody > tr > td {
    height: 88px;
  }


  // ----
  //  Set the Minimum Size for all Default Columns
  // ----
  .deadline-column {
    width: 160px;
  }

  .type-column {
    width: 160px;
  }

  .main-registry-column {
    width: 280px;
  }

  // ----
  //  Set the Team Manager Column
  //  fixing right padding
  // ----
  .team-manager-column {
    width: 100px;
    padding-right: math.div($table-columns-gutter, 2) !important;
    border-right-style: $table-body-border-style !important;
    border-right-width: 2px !important;
    border-right-color: $table-body-border-color !important;
    border-left-style: $table-body-border-style !important;
    border-left-width: 2px !important;
    border-left-color: $table-body-border-color !important;
  }

  // ----
  //  Set the Exact Size for Members Column
  // ----
  .side-team-column {
    width: 72px;
    padding-left: math.div($table-columns-gutter, 2) !important;
  }

  // ----
  //  Set borders for Team Column
  // ----
  .team-manager-column {
    border-right-style: $table-body-border-style !important;
    border-right-width: 2px !important;
    border-right-color: $table-body-border-color !important;
  }

  .side-team-column:not(:first-child) {
    border-left-style: $table-body-border-style !important;
    border-left-width: $table-body-border-width !important;
    border-left-color: $table-body-border-color !important;
  }
}
