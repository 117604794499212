/******
    Define the Style for Auth Pages
******/
body.login:not(.has-auth),
body.signup,
body.create-your-team,
body.email-verification,
body.verify-me,
body.accept-invitation {
  // ----
  //  Define the Body Properties
  // ----
  background-color: $primary;
  height: unset;
  min-height: 100%;


  // ----
  //  Increase field spacing
  // ----
  .form-content > .field {
    margin-bottom: get-em-spacer(5);
  }


  // ----
  //  Set Style for Mobile Phone
  // ----
  padding: 4em 0 2em;

  .auth-sidebar {
    display: none;
  }

  .auth-content {
    box-shadow: rgba(17, 17, 26, 0.1) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
    padding: 2.5em;
    background-color: $white;
    border-radius: $border-radius-huge;
  }


  // ----
  //  Style Panel for Desktop Up screen
  // ----
  @include min-screen('tablet') {
    padding: 4em 0;

    /// Style the Auth Panel as Side-by-Side view
    .auth-panel {
      box-shadow: rgba(17, 17, 26, 0.1) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
      display: flex;
      width: 100%;

      /// Set inner div properties
      > div {
        box-shadow: none;
        display: block;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
        width: 50%;
        padding: 3em 2em;
        background-color: $white;
        border-radius: 0;

        &:first-child {
          border-top-left-radius: $border-radius-big;
          border-bottom-left-radius: $border-radius-big;

          &:not(.auth-content) {
            background-color: rgba(255, 255, 255, 0.4);
          }
        }

        &:last-child {
          border-top-right-radius: $border-radius-big;
          border-bottom-right-radius: $border-radius-big;
        }
      }

      /// Set the icon container and icon sizing
      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > svg {
          font-size: 15em;
        }
      }
    }
  }
}
