body:not(.create-your-team).has-auth {
  .view-content {
    padding: $view-content-vertical-padding $view-content-horizontal-padding;
  }
}

body:not(.create-your-team).with-sidebar {
  padding-left: $sidebar-closed-width;
}

body:not(.create-your-team).with-navbar {
  .view-content {
    padding-top: $navbar-height + $view-content-vertical-padding;
  }
}
