/******
    Style the Sender
******/
.message-sender {
  // ----
  //  Style the Sender
  // ----
  border-radius: $input-border-radius;
  border: solid 1px $cloud-light;
  position: relative;


  // ----
  //  Set the Syntax Helper Button Style
  // ----
  .message-sender-helper {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    z-index: 2;
  }


  // ----
  //  Set custom style for textarea
  // ----
  .input {
    margin-bottom: 0;

    .content textarea {
      background-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 2.5em;
    }
  }


  // ----
  //  Style Attachments
  // ----
  .attachments .attachment {
    display: flex;
    align-items: center;
    padding: .75em;
    border-top: solid 1px $cloud-light;
  }


  // ----
  //  Style the Toolbar
  // ----
  .toolbar {
    width: 100%;
    display: flex;
    border-top: solid 1px $cloud-light;

    .toolbar-item {
      padding: 0 $input-horizontal-padding;
      display: flex;
      align-items: center;
      min-height: 48px;
      color: $cloud-dark;
      font-weight: $semi-bold;

      &.toolbar-item-left {
        border-right: solid 1px $cloud-light;
      }

      &.toolbar-item-right {
        margin-left: auto;
      }

      &.clickable {
        background-color: transparent;
        cursor: pointer;

        @include hover {
          &:hover {
            background-color: $white-shade-soft;
          }
        }
      }
    }
  }
}


/******
    Style Messages
******/
.messages {
  // ----
  //  Style the Messages Container
  // ----
  margin-top: 1em;


  // ----
  //  Style Single Messages
  // ----
  .message-sent {
    position: relative;
    background-color: transparent;
    display: flex;
    padding: .5em;

    /// Remove some markdown preview style
    /// Additional, set some specific style
    .markdown-preview {
      p {
        margin: 0;
      }

      code, pre {
        line-height: 1;
        border-radius: $border-radius;
        background-color: $white-shade;
        border: solid 1px $cloud-light;
      }

      code {
        padding: 0.25em 0.35em 0.3em;
        display: inline-block;
      }

      pre {
        padding: .75em;
        display: block;

        > code {
          border: none;
          background-color: transparent;
          padding: 0;
        }
      }
    }

    /// Set the Avatar Style
    .message-avatar {
      flex: 0 0 $avatar-base-size;
      width: $avatar-base-size;
      margin-right: 1em;
      text-align: center;

      /// Set the Style for time only
      > span {
        vertical-align: baseline;
        display: none;
        color: $text-secondary;
        font-size: .85em;
      }
    }

    /// Set the Header Style
    .message-header {
      margin-bottom: .25em;

      .sender-name,
      .message-time {
        display: inline-block;
      }

      .sender-name {
        font-weight: $semi-bold;
      }

      .message-time {
        margin-left: .5em;
        color: $text-secondary;
        font-size: .85em;
      }
    }

    /// Set the Content
    .message-content {
      flex-grow: 1;
    }

    /// Set Controller Button Group Style
    .message-controller {
      display: none;
      position: absolute;
      top: 0;
      right: .5em;
      transform: translateY(-50%);
      background-color: $white;
      border: solid 1px $cloud-light;
      border-radius: $border-radius;
      padding: .25em;
      z-index: 2;

      @include hover {
        .button:hover {
          background-color: $white-shade;
        }
      }
    }


    /// Style Attachments
    .message-attachment {
      display: block;
      position: relative;

      /// Set property for Image Attachment
      &.image {
        display: inline-block;
        cursor: pointer;
        position: relative;
        border-radius: $border-radius-big;
        overflow: hidden;

        > img {
          max-width: 440px;
          max-height: 300px;
        }

        > .image-name {
          position: absolute;
          padding: 2em .75em .75em;
          left: 0;
          bottom: 0;
          right: 0;
          color: $white;
          font-weight: $bold;
          font-size: 1.25em;
          display: none;
        }

        @include hover {
          &:hover {
            > .image-name {
              background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
              background-size: cover;
              display: block;
            }
          }
        }
      }

      /// Set property for PDF Attachment
      &.pdf {
        display: inline-block;
        padding: 1em;
        border-radius: $border-radius;
        border: solid 1px $cloud-light;
        background-color: $white;

        > .pdf-name {
          display: block;
          margin-top: 1em;
          margin-bottom: 1em;
          border-top: solid 1px $cloud-light;
          padding-top: 1em;
          font-weight: $semi-bold;
        }
      }
    }

    /// Set the Editing Style
    &.editing {
      border-top: solid 1px $cloud-light;
      border-bottom: solid 1px $cloud-light;
    }


    /// Set hover style
    @include hover {
      &:hover:not(.editing) {
        background-color: $white-shade-soft;

        .message-controller {
          display: flex;
        }

        .message-avatar > span {
          display: inline-block;
        }
      }
    }
  }
}
