/******
    Main Sidebar Element
******/
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: $sidebar-closed-width;
  background-color: $sidebar-background;
  padding: $sidebar-padding;
  transition: width $transition-ease $transition-duration,
  box-shadow $transition-ease $transition-duration;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-top-right-radius: $border-radius-big * 3;
  border-bottom-right-radius: $border-radius-big * 3;
  @include smoothShadow(0);
}


/******
    Sidebar menu containers
******/
#sidebar .sidebar-menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .sidebar-menu-content.pull-down {
    margin-top: auto;
  }
}


/******
    Sidebar Logo Width and Position
******/
#sidebar .sidebar-logo {
  width: $sidebar-closed-content-size;

  // ----
  //  Fix Image Width
  // ----
  > img {
    display: block;
    width: $sidebar-logo-width;
    margin: 0 auto;
  }

  // ----
  //  Increase spacing with content on large screen
  // ----
  @include min-screen('tablet') {
    margin-bottom: $sidebar-logo-spacing-on-tablet-up;
  }
}


/******
    Sidebar Menu Element
******/
#sidebar .sidebar-menu-element {
  // ----
  //  Fix the Button Width
  // ----
  color: $sidebar-menu-element-color;
  font-weight: $sidebar-menu-element-font-weight;
  width: $sidebar-closed-content-size;
  height: $sidebar-closed-content-size;
  border-radius: $sidebar-menu-element-border-radius;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: $sidebar-menu-element-spacing 0;
  transition: width $transition-ease $transition-duration,
  background-color $transition-ease $transition-duration,
  color $transition-ease $transition-duration;
  background-color: $sidebar-menu-element-background;

  > div {
    flex-grow: 0;
    flex-shrink: 0;
  }


  // ----
  //  Fix the Icon width based on container
  // ----
  .sidebar-menu-element-icon {
    overflow: hidden;
    width: $sidebar-closed-content-size;
    text-align: center;

    > svg {
      margin: 0;
    }
  }

  .sidebar-menu-element-text {
    margin-left: $sidebar-menu-element-icon-spacing;
    display: none;
  }


  // ----
  //  Style the Active Elements
  // ----
  &.active {
    background-color: $sidebar-menu-element-background-active;
    color: $sidebar-menu-element-color-active;
    @include smoothShadow(4);
  }


  // ----
  //  Style the Hover Behaviour
  // ----
  @include hover {
    &:hover:not(.active) {
      cursor: pointer;
      background-color: $sidebar-menu-element-background-hover;
      color: $sidebar-menu-element-color-hover;
    }
  }
}


/******
    On large screen sidebar will grow
******/
@include hover {
  #sidebar:hover {
    @include min-screen('tablet') {
      width: $sidebar-opened-width;
      @include smoothShadow(4);

      .sidebar-menu-element {
        width: $sidebar-opened-content-width;

        .sidebar-menu-element-text {
          display: block;
        }
      }
    }
  }
}
