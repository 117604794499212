/******
    Settings Button Element
******/
.settings-button {
  display: flex;
  background-color: $settings-button-background;
  border-radius: $settings-button-border-radius;
  padding: $settings-button-padding;
  margin-bottom: $settings-button-padding;
  align-items: center;
  cursor: pointer;
  transition: box-shadow $transition-ease $transition-duration, background-color $transition-ease $transition-duration;
  @include smoothShadow(0);

  .settings-button-icon {
    height: $settings-button-icon-size * 1.25;
    width: $settings-button-icon-size * 1.25;
    border-radius: $border-radius-rounded;
    margin-right: $settings-button-icon-spacer;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: $settings-button-icon-size * .75;
    }
  }

  .header {
    margin-bottom: 0;
  }

  &.active {
    background-color: $primary;
    color: $white;
    @include smoothShadow(4);
  }
}
