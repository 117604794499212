/******
    Override the Default Color for Panel Body in White Shade only
******/
.panel > .head.has-background-white-shade,
.panel > .body.has-background-white-shade,
.panel > .foot.has-background-white-shade {
  background-color: $white-shade-soft !important;
}


/******
    Change the Bordered Menu for Tabs
******/
.tabs > .bordered.horizontal.menu {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;

  > .menu-item {
    font-weight: $semi-bold;
    color: $text-secondary;

    &.active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}
