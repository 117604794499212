/******
    Activity Item Variables
******/
$__activity-item-checker-size: 1.5rem;
$__activity-item-tool-width: 2.85rem;


/******
    Single Activity Item Element
******/
.activity-items .activity-item {
  display: flex;
  margin-bottom: .5em;

  // ----
  //  Set the Tool base size
  // ----
  .activity-item-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 $__activity-item-tool-width;
    width: $__activity-item-tool-width;
    height: $computed-input-height;
  }

  // ----
  //  Set the Main Content style
  // ----
  .activity-item-content {
    flex-grow: 1;

    .activity-item-content-text {
      font-weight: $semi-bold;
      padding: $input-vertical-padding $input-horizontal-padding $input-vertical-padding 0;
    }
  }


  // ----
  //  Set the Toggle Element Style
  // ----
  .activity-item-checker {
    display: inline-block;
    width: $__activity-item-checker-size;
    height: $__activity-item-checker-size;
    background-color: $white;
    border-style: solid;
    border-width: 2px;
    border-color: $cloud;
    border-radius: $border-radius-rounded;
    position: relative;
    transition: background-color $transition-ease $transition-duration,
    border-color $transition-ease $transition-duration;

    > .icon {
      transition: color $transition-ease $transition-duration;
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: transparent;
    }
  }

  &:not(.no-toggle) .activity-item-checker {
    cursor: pointer;

    @include hover {
      &:hover {
        border-color: $success;

        > .icon {
          color: $success;
        }
      }
    }
  }


  // ----
  //  Set the Done State
  // ----
  &.done {
    /// Style the Text
    .activity-item-content .activity-item-content-text {
      font-style: italic;
      text-decoration: line-through;
      color: $text-secondary;
    }

    /// Style the Checker
    .activity-item-checker {
      border-color: $success;
      background-color: transparentize($success, .25);

      > .icon {
        color: $white !important;
      }
    }
  }
}
