// ----
//  Layout
// ----
$view-content-horizontal-padding: 2.5em;
$view-content-vertical-padding: 1.5em;


// ----
//  Navbar Element
// ----
$navbar-background: $body-background;
$navbar-content-size: $avatar-base-size;
$navbar-padding: 1em;

$navbar-height: $navbar-content-size + ($navbar-padding * 2);

$navbar-element-spacing: .5rem;

$navbar-bottom-border-color: $white-shade-2;


// ----
//  Settings Button
// ----
$settings-button-background: $white-shade-soft;
$settings-button-padding: 1.5em;
$settings-button-border-radius: $border-radius-big * 3;
$settings-button-icon-size: 3em;
$settings-button-icon-spacer: 1.25em;


// ----
//  Sidebar Element
// ----
$sidebar-background: $white-shade;
$sidebar-closed-content-size: 36px;
$sidebar-opened-content-width: 216px;
$sidebar-padding: 16px;

$sidebar-closed-width: $sidebar-closed-content-size + ($sidebar-padding * 2);
$sidebar-opened-width: $sidebar-opened-content-width + ($sidebar-padding * 2);

$sidebar-logo-width: 26px;
$sidebar-logo-spacing-on-tablet-up: 3em;

$sidebar-menu-element-color: rgba(0, 0, 0, 0.5);
$sidebar-menu-element-background: rgba(255, 255, 255, 0.5);
$sidebar-menu-element-color-hover: $primary;
$sidebar-menu-element-background-hover: rgba(255, 255, 255, 0.5);
$sidebar-menu-element-color-active: $white;
$sidebar-menu-element-background-active: $primary;

$sidebar-menu-element-font-weight: $bold;
$sidebar-menu-element-spacing: 1.5em;
$sidebar-menu-element-border-radius: $border-radius-big;
$sidebar-menu-element-icon-spacing: 0.5em;
